import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import { REMOVE_REDIRECT_PATH } from "../../constants/contexConstant";
import { LOGIN, SIGNUP_COMMUNITY_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { handleRedirection } from "../../helpers/routingHelper";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import FormInput from "../../shared/ui/Form/FormInput";
import { leaseSignupWithPasswordValidator } from "../../shared/ui/Form/schema";
import OTPModal from "../../shared/ui/OTPModal";

const validateSignupForm = (formData, getOtp) => {
    const newErrors = {};

    if (getOtp) {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};

const SignUpAdminPage = ({ code, planDetails }) => {
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [formData, setFormData] = useState({
        otp: "",
    });

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const handleSubmit = async (values, actions, mode) => {
        if (mode) {
            handleResentOTP(mode, values.username, () => null, dispatch);
            return;
        } else {
            setLoading(true);
            try {
                let reqData = {
                    name: values.name,
                    email: values.email,
                    phone: `91${values.mobile}`,
                    flatNumber: values.flat,
                    password: values.password,
                };

                if (otp) {
                    reqData.otp = formData.otp;
                }

                let endpoint = `${SIGNUP_COMMUNITY_ENDPOINT}?code=${code}`;
                await request.post({
                    endpoint,
                    body: reqData,
                });

                if (otp) {
                    await auth.login({
                        username: values.email,
                        password: values.password,
                        otpEnable: false,
                    });
                    const profile = await auth.getUserProfile(dispatch);
                    let status = profile?.data?.investor_profile?.plant?.status;
                    const roles = profile?.data?.investor_profile?.roles;

                    handleRedirection(
                        state,
                        history,
                        roles,
                        status,
                        code,
                        planDetails,
                        dispatch
                    );
                } else {
                    setOtp((prev) => !prev);
                }

                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            }
        }
    };
    const [otp, setOtp] = useState(false);

    const initialValues = {
        name: "",
        password: "",
        email: "",
        mobile: "",
        flat: "",
    };
    return (
        <div className="px-1">
            <div className="flex flex-col items-start gap-1 mb-3">
                <H4 className="font-semibold">Create your account</H4>
                <p className="text-gray-secondary text-xs">
                    {planDetails?.plantMeta?.plantName}
                </p>
            </div>
            <div className="flex flex-col gap-1.5">
                <Formik
                    initialValues={initialValues}
                    validationSchema={leaseSignupWithPasswordValidator}
                    onSubmit={handleSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                        isValid,
                        dirty,
                    }) => (
                        <>
                            <FormInput
                                label={"Full Name"}
                                name={"name"}
                                type={"text"}
                                required
                            />
                            <div>
                                <FormInput
                                    label={"Mobile"}
                                    name={"mobile"}
                                    type={"text"}
                                    required
                                />
                                <span className="text-xs text-gray-500">
                                    An OTP will be sent to your phone number for
                                    verification
                                </span>
                            </div>
                            <FormInput
                                label={"Email"}
                                name={"email"}
                                type={"text"}
                                required
                            />
                            <FormInput
                                label={"Account Password"}
                                name={"password"}
                                type={"text"}
                                required
                            />
                            <FormInput
                                label={"Flat Number"}
                                name={"flat"}
                                type={"text"}
                                required
                            />
                            <OTPModal
                                isOpen={otp}
                                onClose={() => setOtp(false)}
                                formData={formData}
                                errors={errors}
                                handleResentOTP={(mode) =>
                                    handleSubmit(values, { resetForm }, mode)
                                }
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                            />
                            <Button
                                className={
                                    "bg-black text-white mt-1 !rounded-[3rem] w-full"
                                }
                                type="submit"
                                disabled={isSubmitting || !(isValid && dirty)}
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                            >
                                Continue
                            </Button>
                        </>
                    )}
                </Formik>
            </div>
            <div className="flex flex-col gap-1 mt-1">
                <div className="flex items-center justify-end mb-5">
                    <P className="flex items-center gap-0.2">
                        Already have account?
                        <span
                            className="text-blue underline"
                            role="button"
                            onClick={() =>
                                history.push(`/lease${LOGIN}?code=${code}`)
                            }
                        >
                            Sign in
                        </span>
                    </P>
                </div>
            </div>
        </div>
    );
};

export default SignUpAdminPage;
